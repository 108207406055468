import { useEffect } from "react";
import { wormholeConnectHosted } from "@wormhole-foundation/wormhole-connect";

import wormholeLogo from "../../img/wormhole.svg";
import pontemLogo from "../../img/logo_white.svg";

const pageTitle = "Cross-Chain Swap by Wormhole & Pontem";

const WormholeWidgetWrapper = () => {
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  useEffect(() => {
    const container = document.getElementById("bridge-container");

    if (container) {
      wormholeConnectHosted(container, {
        config: {
          rpcs: {
            Ethereum: "https://eth.proxy.pontem.network",
            Bsc: "https://bsc.proxy.pontem.network",
            Polygon: "https://polygon.proxy.pontem.network",
            Avalanche: "https://avalanche.proxy.pontem.network",
            Optimism: "https://optimism.proxy.pontem.network",
            Arbitrum: "https://arbitrum.proxy.pontem.network",
            Base: "https://base.proxy.pontem.network",
            Fantom: "https://fantom.proxy.pontem.network",
            Gnosis: "https://gnosis.proxy.pontem.network",
            Solana: "https://solana-rpc.proxy.pontem.network",
            Aptos: "https://fullnode.mainnet.aptoslabs.com/v1",
          },
        },
      });
    }
  }, []);

  return (
    <div className="bridge-wrapper">
      <div className="bridge-logos">
        <img
          className="bridge-logos__image"
          src={pontemLogo}
          alt="pontem logo"
        />
        <span className="bridge-logos__text">x</span>
        <img
          className="bridge-logos__image"
          src={wormholeLogo}
          alt="wormhole logo"
          style={{ width: "140px" }}
        />
      </div>
      <div id="bridge-container"></div>
    </div>
  );
};

export default WormholeWidgetWrapper;
