import React, { FC, useCallback, useState } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { FARMS_LINK } from "../../../consts";
import { Link, useLocation } from "react-router-dom";
import { Airdrop } from "./Airdrop";

const topList = [
  {
    label: "Swap",
    name: "swap",
    icon: "menu-link menu-link_type_swap",
    to: { name: "swap" },
    url: "https://liquidswap.com",
  },
];

const bridgeList = [
  {
    label: "LayerZero",
    name: "bridge",
    icon: "menu-link earn-link_type_layerzero",
    to: { name: "bridge" },
    url: "/",
  },
  {
    label: "Wormhole",
    name: "wormhole",
    icon: "menu-link earn-link_type_wormhole",
    to: { name: "wormhole" },
    url: "/wormhole-widget",
  },
];

const earnList = [
  {
    label: "Pools",
    name: "pools",
    icon: "menu-link menu-link_type_pools",
    to: { name: "pools" },
  },
  {
    label: "Farms",
    name: "stakes",
    icon: "menu-link menu-link_type_stakes",
    to: { name: "stakes" },
  },
  {
    label: "Staking",
    name: "aptos-staking",
    icon: "menu-link menu-link_type_staking",
    to: { name: "aptos-staking" },
  },
];

const earnListItemTemplate = (item, options) => {
  return (
    <a
      className={classNames("p-menuitem-link")}
      href={
        item.name === "stakes"
          ? FARMS_LINK
          : `https://liquidswap.com/#/${item.name}`
      }
      rel="noreferrer noopenner"
    >
      {item.icon && (
        <span className={classNames("p-menuitem-icon", item.icon)}></span>
      )}
      {item.label}
    </a>
  );
};

const earnListItemsWithTemplate = earnList.map((item) => ({
  ...item,
  template: earnListItemTemplate,
}));

const analyticsList = [
  {
    label: "Stats",
    name: "stats",
    icon: "menu-link menu-link_type_stats",
    to: { name: "stats" },
  },
];

const analyticsListItemTemplate = (item, options) => {
  return (
    <a
      className={classNames("p-menuitem-link")}
      href={`https://liquidswap.com/#/${item.name}`}
      rel="noreferrer noopenner"
    >
      {item.icon && (
        <span className={classNames("p-menuitem-icon", item.icon)}></span>
      )}
      {item.label}
    </a>
  );
};

const analyticsListItemsWithTemplate = analyticsList.map((item) => ({
  ...item,
  template: analyticsListItemTemplate,
}));

const moreList = [
  {
    icon: "pi pi-info-circle",
    label: "Learn more",
    url: "https://home.liquidswap.com",
  },
  {
    icon: "pi pi-book",
    label: "Docs",
    url: "https://docs.liquidswap.com",
  },
  {
    icon: "pi pi-discord",
    label: "Discord",
    url: "https://discord.com/invite/44QgPFHYqs",
  },
  {
    icon: "pi pi-comments",
    label: "Feedback for partners",
    url: "https://form.typeform.com/to/ggYvlrgj",
  },
  {
    icon: "pi pi-telegram",
    label: "Telegram Community",
    url: "https://t.me/pontemnetworkchat",
  },
];

const moreListItemTemplate = (item, options) => {
  if (!item.url) {
    return null;
  }

  return (
    <a
      href={item.url}
      className="p-menuitem-link"
      target="_blank"
      role="menuitem"
      tabIndex={0}
      rel="noreferrer noopener"
    >
      {item.icon && (
        <span className={classNames("p-menuitem-icon", item.icon)}></span>
      )}
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );
};

const moreListItemsWithTemplate = moreList.map((item) => ({
  ...item,
  template: moreListItemTemplate,
}));

export const MobileMenu: FC = () => {
  const { pathname } = useLocation();

  const bridgeTemplate = (item) => {
    return (
      <Link
        className={`p-menuitem-link ${pathname === item.url && "active"}`}
        to={item.url}
        rel="noreferrer noopenner"
        onClick={onHide}
      >
        {item.icon && (
          <span className={classNames("p-menuitem-icon", item.icon)}></span>
        )}
        {item.label}
      </Link>
    );
  };

  const topListItemTemplate = (item, options) => {
    if (item.name === "swap") {
      return (
        <a
          className="p-menuitem-link"
          href={item.url}
          rel="noreferrer noopenner"
        >
          {item.icon && (
            <span className={classNames("p-menuitem-icon", item.icon)}></span>
          )}
          Swap
        </a>
      );
    }

    if (["bridge", "kana"].includes(item.name)) {
      return (
        <Link
          className={`p-menuitem-link ${pathname === item.url && "active"}`}
          to={item.url}
          rel="noreferrer noopenner"
          onClick={onHide}
        >
          {item.icon && (
            <span className={classNames("p-menuitem-icon", item.icon)}></span>
          )}
          {item.label}
        </Link>
      );
    }

    return (
      <a
        className={classNames("p-menuitem-link")}
        href={`https://liquidswap.com/#/${item.name}`}
        rel="noreferrer noopenner"
      >
        {item.icon && (
          <span className={classNames("p-menuitem-icon", item.icon)}></span>
        )}
        {item.label}
        {item.name === "stakes" && (
          <img
            className="stakes-icon ml-2"
            src="@/assets/hydroponics.svg"
            alt="Stakes"
          />
        )}
      </a>
    );
  };

  const topListItemsWithTemplate = topList.map((item) => ({
    ...item,
    template: topListItemTemplate,
  }));

  const bridgeItemsWithTemplate = bridgeList.map((item) => ({
    ...item,
    template: bridgeTemplate,
  }));

  const [isVisible, setVisible] = useState(false);

  const onToggle = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Button
        className="lg: ml-2 menu-button p-button-rounded p-button-secondary social-button mobile-menu"
        icon="pi pi-bars"
        type="button"
        onClick={onToggle}
      />
      <Sidebar
        className="sidebar dark-background"
        visible={isVisible}
        baseZIndex={10000}
        position="right"
        onHide={onHide}
      >
        <div className="menu-header">
          <h1 className="menu-title">Menu</h1>
          <div className="menu-settings">
            <Button className="button" icon="pi pi-times" onClick={onHide} />
          </div>
        </div>
        <hr className="divider" />
        <div className="airdrop-wrapper">
          <Airdrop className="airdrop-mobile" />
        </div>
        <hr className="divider" />

        <h2 className="menu-sub-title">Swap</h2>

        <Menu
          className="mobile-menu"
          id="mobile-menu"
          popup={false}
          model={topListItemsWithTemplate}
        />

        <h2 className="menu-sub-title">Bridge</h2>

        <Menu
          className="mobile-menu"
          id="mobile-menu-bridge"
          popup={false}
          model={bridgeItemsWithTemplate}
        />

        <h2 className="menu-sub-title">Earn</h2>
        <Menu
          className="mobile-menu"
          id="mobile-menu-earn"
          popup={false}
          model={earnListItemsWithTemplate}
        />

        <h2 className="menu-sub-title">Analytics</h2>
        <Menu
          className="mobile-menu"
          id="mobile-menu-analytics"
          popup={false}
          model={analyticsListItemsWithTemplate}
        />

        <h2 className="menu-sub-title">More</h2>
        <Menu
          className="mobile-menu"
          id="mobile-menu-more"
          popup={false}
          model={moreListItemsWithTemplate}
        />
      </Sidebar>
    </>
  );
};
