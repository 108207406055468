import { useCallback, useRef, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useLocation } from "react-router-dom";
import { Airdrop } from "./components/Airdrop";

import info from "../../img/info.svg";
import logo from "../../img/logo.svg";

import { headerLinksList, socialMenuItems } from "../../consts";

import { MobileMenu } from "./components/MobileMenu";

const socialMenuItemTemplate = (item, options) => {
  return (
    <a
      className={classNames(options.className, "social-menu__item")}
      href={item.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={classNames(item.icon, "p-menuitem-icon")}></span>
      <span>{item.label}</span>
    </a>
  );
};

const socialMenuItemsWithTemplate = socialMenuItems.map((item) => ({
  ...item,
  template: socialMenuItemTemplate,
}));

export const Header = () => {
  const isSmallDevice = useMediaQuery("(max-width: 1600px)");

  const [isEarnMenuOpen, setIsEarnMenuOpen] = useState(false);

  const toggleEarnMenu = useCallback(() => {
    setIsEarnMenuOpen(!isEarnMenuOpen);
  }, [isEarnMenuOpen]);

  const [isBridgeMenuOpen, setIsBridgeMenuOpen] = useState(false);

  const toggleBridgeMenu = useCallback(() => {
    setIsBridgeMenuOpen(!isBridgeMenuOpen);
  }, [isBridgeMenuOpen]);

  const earnMenu = useRef<Menu>(null);
  const socialMenu = useRef<Menu>(null);
  const bridgeMenu = useRef<Menu>(null);

  let { pathname } = useLocation();

  return (
    <div className="header">
      <div className="header__menu grid-child__first-row">
        <a
          className="header__logo"
          href="https://liquidswap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="logo" width="40" height="40" />
        </a>
        <a
          className="learn-button"
          href="https://home.liquidswap.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="learn-button__icon"
            src={info}
            width="18"
            height="18"
            alt=""
          />
          Learn more
        </a>
      </div>
      <div className="header__links grid-child__first-row">
        <nav className="links">
          <ul className="links__list">
            {headerLinksList.map((link) => {
              const activeBridgeClass =
                link.url === pathname ||
                (link.items &&
                  link.items?.some((item) => item.url === pathname))
                  ? "active"
                  : "";

              return link.items ? (
                link.label === "Earn" ? (
                  <li
                    className="links__list-item links__list-item_type_earn"
                    key={link.label}
                  >
                    <button
                      className="link link_type_button header__link"
                      aria-haspopup="true"
                      aria-controls="link__dropdown"
                      onClick={(event) => {
                        earnMenu.current?.toggle(event);
                        toggleEarnMenu();
                      }}
                    >
                      {!isSmallDevice && (
                        <div className="link__icon link__icon_type_earn" />
                      )}
                      Earn
                      <i
                        className={`link__angle ${
                          isEarnMenuOpen
                            ? "link__angle_toggle_yes"
                            : "link__angle_toggle_no"
                        }`}
                      />
                    </button>

                    <Menu
                      className="link__dropdown"
                      id="link__dropdown"
                      ref={earnMenu}
                      popup
                      model={link.items}
                      onHide={() => toggleEarnMenu()}
                    />
                  </li>
                ) : link.label === "Swap" ? (
                  <li
                    className={`links__list-item ${activeBridgeClass}`}
                    key={link.label}
                  >
                    <a
                      className="link header__link"
                      href={link.url}
                      rel="noopener noreferrer"
                    >
                      {!isSmallDevice && (
                        <div className="link__icon link__icon_type_swap-wide" />
                      )}
                      {link.label}
                    </a>
                  </li>
                ) : (
                  link.label === "Bridge" && (
                    <li
                      className={`links__list-item ${activeBridgeClass}`}
                      key={link.label}
                    >
                      <button
                        className="link link_type_button header__link"
                        aria-haspopup="true"
                        aria-controls="link__dropdown"
                        onClick={(event) => {
                          bridgeMenu.current?.toggle(event);
                          toggleBridgeMenu();
                        }}
                      >
                        {!isSmallDevice && (
                          <div
                            className={`link__icon link__icon_type_bridge`}
                          />
                        )}
                        {link.label}
                        <i
                          className={`link__angle ${
                            isBridgeMenuOpen
                              ? "link__angle_toggle_yes"
                              : "link__angle_toggle_no"
                          }`}
                        />
                      </button>
                      <Menu
                        className="link__dropdown"
                        id="link__dropdown"
                        ref={bridgeMenu}
                        popup
                        model={link.items}
                        onHide={() => toggleBridgeMenu()}
                      />
                    </li>
                  )
                )
              ) : (
                <li
                  className={`links__list-item ${activeBridgeClass}`}
                  key={link.label}
                >
                  <a
                    className="link header__link"
                    href={link.url}
                    rel="noopener noreferrer"
                  >
                    {link.desktopIcon && !isSmallDevice && (
                      <div
                        className={`link__icon link__icon_type_${link.desktopIcon}`}
                      />
                    )}
                    {link.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="header__actions grid-child__first-row">
        <Airdrop className="hide-desktop-airdrop-button" />
        <MobileMenu />
        <Button
          className="ml-3 p-button-rounded p-button-secondary social-button"
          icon="pi pi-ellipsis-h"
          type="button"
          aria-controls="social_menu"
          onClick={(event) => socialMenu.current?.toggle(event)}
        >
          <div className="social-button__icon social-button__icon_type_ellipsis" />
        </Button>
        <Menu
          id="social_menu"
          className="social-menu"
          ref={socialMenu}
          model={socialMenuItemsWithTemplate}
          popup
        />
      </div>
    </div>
  );
};
